.create-user {
    display: flex;
    justify-content: center;
    height: calc(100% - 75px) !important;

    form {
        width: 600px;
        margin-top: 40px;
    }

    label {
        color: #afafaf;
        margin-bottom: 5px;
        display: block;
    }
    .MuiOutlinedInput-root {
        margin-bottom: 20px;
        input {
            background-color: #afafaf;
            border-radius: 10px;
            outline: none;
            height: 10px;
            &:hover{
                outline: none;
            }
            &:focus {
                background-color: #fff;
            }
        }
    }

    button {
        background-color: #186542;
        &:hover {
            background-color: #186542;
        }
    }

}