.cradle-stats-average {
    margin-top: 30px;
    .cradle-status {
        height: 50px;
        width: 100%;
        margin: 5px 1px;
        text-align: center;
        h5 {
            margin: 5px;
        }

        p {
            margin: 5px;
            font-weight: 600;
        }

        &:first-child {
            p {
                color: #00704A;
            }
        }

        &:nth-child(2) {
            p {
                color: #D8493E;
            }
        }
    }
    .device-stats {
        .MuiLinearProgress-root {
            height: 18px;
            margin: 2px 1px;
            border-radius: 5px;
            background-color: transparent;
            display: inline-block;
            width: 80%;
            margin-top: 6px;
            border-bottom: 2px solid #313131;
        }
        a {
            text-decoration: none;
            color: #fff;
            font-weight: 500;
            font-size: 14px;
            display: block;
            text-transform: capitalize;
        }

        .stats-num{
            float: right;
            font-weight: 500;
            width: 18%;
            white-space: nowrap;
            color: #fff;

            span {
                font-size: 16px;
                &:last-child {
                    color: #D8493E;
                }

            }
        }

    }

}