.dashboard {
    color: #fff;
    border-radius: 7px 0px 0px 0px;
    height: calc(100% - 80px);

    .header {
        background-color: #000000;
        padding: 20px 8px 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h1 {
            font-size: 22px;
            font-weight: 500;
            color: #00704A;
            margin: 0 0 0 10px;
        }

        .right-section {
            margin-right: 5px;
            font-size: 16px;
            display: flex;
            align-items: center;

            .time {
                color: #00704A;
                padding: 2px 7px;
                border-radius: 5px;
                margin-right: 20px;
                font-weight: 500;
            }
            .avatar {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-right: 26px;
                .MuiAvatar-circular {
                    color: #00704A;
                    font-weight: 500;
                }
            }
        }
    }

    .graph-panels {
        display: flex;
        justify-content: space-between;
        margin-left: 3px;
        padding-top: 5px;

        .MuiBox-root {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            width: 300px;
            &:nth-child(2) {
                margin-right: 4%;
            }

            .MuiCard-root {
                width: 100%;
                margin: 2px 1px;
                .MuiCardContent-root {
                    padding: 14px 0px;
                }
            }
        }
    }

    .top-alert {
        margin: 10px 8px;
        h3 {
            margin: 16px 0;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 10px;
        }
        .create-ticket {
            background-color: #00704a;
            color: #fff;
            border: none;
            border-radius: 2px;
            padding: 2px;
            font-size: 12px;
        }
    }

    .top-devices {
        margin: 10px 8px;
        padding-top: 16px;

        h3 {
            margin: 5px 0;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 10px;
        }
    }
}

.create-ticket {
    background-color: #00704a;
    color: #fff;
    border: none;
    border-radius: 2px;
    padding: 3px;
}