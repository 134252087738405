@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
    &::-webkit-scrollbar {
        width: $size;
        height: $size;
    }

    &::-webkit-scrollbar-thumb {
        background: $foreground-color;
    }

    &::-webkit-scrollbar-track {
        background: $background-color;
    }

    & {
        scrollbar-face-color: $foreground-color;
        scrollbar-track-color: $background-color;
    }
}


.main-sidebar {
    flex-shrink: 0;
    padding: 0 0 0 0;

    .MuiDrawer-paper {
        box-sizing: 'border-box';
        background-color: #2E2E2E;
        overflow-x: hidden;
        @include scrollbars(5px, #383838, #ccc);


        &::-webkit-scrollbar {
            border-radius: '10px';
            height: '10px';
            width: '5px';
        }

        &::-webkit-scrollbar-thumb {
            background: '#1976d2';
            border-radius: '10px';
            height: '5px';
        }

        &::-webkit-scrollbar-track {
            border-radius: '10px';
        }

        ul {
            .MuiListItem-root {
                color: #B1B1B1;
                &.active {
                    background-color: #00704A;
                    color: white;
                }
            }
        }

    }

    .toggle-icon {
        cursor: pointer;
        margin-right: 24px;
        margin-left: 10px;
    }

}