.cradle-stats {
    .cradle-status {
        height: 50px;
        width: 49%;
        margin: 5px 1px;
        text-align: center;

        h5 {
            margin: 5px;
            white-space: nowrap;
        }

        p {
            margin: 5px;
            font-weight: 600;
        }

        &:first-child {
            p {
                color: #00704A;
            }
        }

        &:nth-child(2) {
            p {
                color: #D8493E;
            }
        }
    }

}