body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Montserrat';
    background-color: #000000;
}

.pre-loader {
    margin: auto;
    width: 75px;
    display: block;
}

.auth {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
    margin: auto;
    width: 47%;
    border-radius: 10px;
    padding: 15px 70px;
    margin-top: 120px;
    padding-bottom: 40px;

    h1 {
        color: #363740;
        font-size: 32px;
        font-weight: 600;
        line-height: 1;
    }

    span.MuiTypography-root {
        font-size: 14px;
        font-weight: 400;
        display: block;
        text-align: center;
        margin-top: 20px;
    }

    button {
        background-color: #186542;
        border-radius: 20px;
        display: block;
        margin: 20px auto;
        width: 90%;
        &:hover {
            background-color: #186542;
        }
    }
    p {
        text-align: center;
        a {
            color: #363636;
            font-size: 14px;
        }
    }

    .main-logo {
        margin-top: -100px;
    }
    .Mui-error {
        color: red !important;
        display: block;
        text-align: center;
    }
    .input-field {
        .MuiInputBase-root {
            border-radius: 100px;
            width: 90%;
            margin: 0 auto;
            margin-bottom: 10px;
            height: 45px;
        }
    }
}

.MuiAppBar-root {
    background-color: transparent !important;
}

.user-link {
    text-decoration: none;
    padding: 7px 12px;
    background-color: #186542;
    color: #fff;
    border-radius: 10px;
    margin-top: -5px;
}

.stats-progress-red {
    &.MuiLinearProgress-bar {
        background-color: "#D8493E"
    }
}

.stats-progress-blue {
    &.MuiLinearProgress-bar {
        background-color: "#007BFF"
    }
}

.main-border {
    border-width: 3px 0 0 3px; 
    border-style: solid;
    border-color: #56675D;
    border-radius: 7px 0px 0px 0px;
    height: calc(100% - 115px);
}