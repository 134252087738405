.device-detail {
    display: flex;
    flex-wrap: wrap;
    border-width: 3px 0 0 3px;
    border-style: solid;
    border-color: #56675D;
    border-radius: 7px 0px 0px 0px;
    height: calc(100% - 85px);
    padding: 5px;

    .MuiTable-root {
        width: 49%;
        margin: 5px;
        border-radius: 0;
        background-color: #535353;

        tr {
            td {
                border: 1px solid #dee2e6 !important;
                color: #fff;

                &:first-child {
                    background-color: #186542;
                    width: 30%;
                }
            }
        }
    }

    .MuiPaper-root {
        width: 49%;
        margin: 5px;
        border-radius: 0;
    }

    .device-metrics {
        .MuiLinearProgress-root {
            height: 32px;
            margin: 2px 0;
            border-radius: 5px;
            background-color: transparent;
            display: inline-block;
            width: 90%;
        }

        a {
            text-decoration: none;
            color: #2e2e2e;
            font-weight: 700;
            font-size: 14px;
            display: block;
        }

        .stats-num {
            float: right;
            font-weight: 700;

            span {
                font-size: 12px;
                color: #fff;
            }
        }
        
        h2 {
            color: #fff;
            font-size: 24px;
            margin-bottom: 10px;
        }
        h3 {
            color: #fff;
        }
    }
}