.cradle-stats {
    .cradle-status {
        height: 50px;
        width: 49% !important;
        margin: 5px 1px;
        text-align: center;
        h5 {
            margin: 5px;
        }
        p {
            margin: 5px;
        }
    }

}